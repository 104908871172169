// src/pages/LeadDetails.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';

// Lead
import { loadSingleLead, clearSelectedLead, updateLeadThunk } from '../slices/leadsSlice';
import { Lead } from '../types/lead';

// Comments
import { loadComments, addComment, clearComments } from '../slices/commentSlice';
import { Comment as CommentType } from '../types/comment';

// Platform
import {
    createPlatformUserThunk,
    getPlatformUserThunk,
    clearPlatformData
} from '../slices/platformSlice';
import { PlatformUser, Position } from '../services/platformService';

// Auth
import { hasPermission } from '../utils/permissionUtils';
import { Role } from '../utils/permissions';

// UI
import Modal from '../components/Modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const STATUS_OPTIONS = [
    'new',
    'no answer',
    'newer answer',
    'slip away',
    'not interested',
    'no pot',
    'callback',
    'reassign',
    'active',
    'depositor',
    'initial call',
    'wrong info',
    'invalid language',
    'wrong number',
];

const LeadDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // =======================
    // 1. Стан лідів
    const {
        selectedLead,
        loading: leadLoading,
        error: leadError
    } = useAppSelector((state) => state.leads);

    // =======================
    // 2. Стан коментарів
    const {
        comments,
        loading: commentsLoading,
        error: commentsError
    } = useAppSelector((state) => state.comments);

    // =======================
    // 3. Стан платформи
    const {
        platformUser,
        userPassword,
        loading: platformLoading,
        error: platformError
    } = useAppSelector((state) => state.platform);

    // =======================
    // 4. Стан авторизації (роль)
    const role = useAppSelector((state) => state.auth.role) as Role;
    const canEditStatus = hasPermission(role, 'EDIT_STATUS');
    const canEditManager = hasPermission(role, 'EDIT_MANAGER');

    // -----------------------
    // Ефекти
    useEffect(() => {
        if (id) {
            const leadId = parseInt(id, 10);
            if (isNaN(leadId)) {
                navigate('/leads');
            } else {
                // load lead info
                dispatch(loadSingleLead(leadId));
                // load comments
                dispatch(loadComments(leadId));
                // спробуємо отримати platform user
                dispatch(getPlatformUserThunk(leadId))
                    .unwrap()
                    .catch(() => {
                        // якщо 404 або інша помилка, означає user на платформі не існує
                    });
            }
        }

        return () => {
            dispatch(clearSelectedLead());
            dispatch(clearComments());
            dispatch(clearPlatformData());
        };
    }, [id, dispatch, navigate]);

    // -----------------------
    // Створення користувача на платформі
    const handleCreatePlatformUser = () => {
        if (!selectedLead) return;
        dispatch(createPlatformUserThunk(selectedLead.id))
            .unwrap()
            .then((res) => {
                toast.success(`Аккаунт створено. Пароль: ${res.userPassword}`);
            })
            .catch((err) => {
                toast.error(`Помилка створення: ${err}`);
            });
    };

    // -----------------------
    // Модалка для редагування ліда
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editManager, setEditManager] = useState<number | null>(null);
    const [editStatus, setEditStatus] = useState<string>('');

    const handleOpenEditModal = () => {
        if (selectedLead) {
            setEditManager(selectedLead.user.id);
            setEditStatus(selectedLead.status);
            setIsEditModalOpen(true);
        }
    };
    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setEditManager(null);
        setEditStatus('');
    };
    const handleSaveEdit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedLead) return;
        const payload: any = { leadIds: [selectedLead.id] };
        if (canEditStatus) payload.status = editStatus;
        if (canEditManager && editManager !== null) payload.manager = editManager;

        dispatch(updateLeadThunk(payload))
            .unwrap()
            .then(() => {
                toast.success("Лід успішно оновлено!");
            })
            .catch((err) => console.error(err));
        handleCloseEditModal();
    };

    // -----------------------
    // Модалка для додавання коментаря
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newComment, setNewComment] = useState('');

    const handleAddComment = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedLead && newComment.trim() !== '') {
            dispatch(addComment({ message: newComment, leadId: selectedLead.id }))
                .unwrap()
                .then(() => {
                    toast.success('Коментар додано');
                })
                .catch((err) => {
                    toast.error(`Помилка: ${err}`);
                });
            setNewComment('');
            setIsModalOpen(false);
        }
    };

    // -----------------------
    // Перевіряємо стани
    if (leadLoading) {
        return <div className="p-4">Завантаження ліда...</div>;
    }
    if (leadError) {
        return <div className="p-4 text-red-500">Помилка: {leadError}</div>;
    }
    if (!selectedLead) {
        return <div className="p-4">Лід не знайдено.</div>;
    }

    // -----------------------
    // Рендер
    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            {/* Кнопка Назад */}
            <button
                onClick={() => navigate(-1)}
                className="mb-6 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
            >
                Назад до Лідів
            </button>

            {/* Кнопка Редагування Ліда */}
            {(canEditStatus || canEditManager) && (
                <button
                    onClick={handleOpenEditModal}
                    className="ml-4 mb-6 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition"
                >
                    Редагувати Лід
                </button>
            )}

            {/* Основна інформація про лід */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                {/* Колонка 1 */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-semibold mb-4">Основна Інформація</h2>
                    <div className="space-y-2">
                        <p><strong>ID:</strong> {selectedLead.id}</p>
                        <p><strong>Ім'я:</strong> {selectedLead.userName}</p>
                        <p><strong>Email:</strong> {selectedLead.email}</p>
                        <p><strong>Телефон:</strong> {selectedLead.phone}</p>
                        <p><strong>Країна:</strong> {selectedLead.country}</p>
                        <p><strong>Мова:</strong> {selectedLead.language}</p>
                        <p><strong>Статус:</strong> {selectedLead.status}</p>
                        <p><strong>Коментар:</strong> {selectedLead.comment || '—'}</p>
                    </div>
                </div>

                {/* Колонка 2 */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-semibold mb-4">Афіліат</h2>
                    <div className="space-y-2">
                        <p><strong>ID Афіліата:</strong> {selectedLead.affiliateData.id}</p>
                        <p><strong>Назва Офера:</strong> {selectedLead.affiliateData.offerName}</p>
                        <p><strong>Офер:</strong> {selectedLead.affiliateData.offer}</p>
                        <p><strong>URL:</strong> {selectedLead.affiliateData.url || '—'}</p>
                        <p><strong>Ім'я Користувача:</strong> {selectedLead.affiliateData.userName || '—'}</p>
                        <p><strong>Реферал:</strong> {selectedLead.affiliateData.referral || '—'}</p>
                        <p><strong>Опис:</strong> {selectedLead.affiliateData.description || '—'}</p>
                    </div>
                </div>

                {/* Колонка 3 */}
                <div className="bg-white p-6 rounded-lg shadow">
                    <h2 className="text-xl font-semibold mb-4">Менеджер</h2>
                    <div className="space-y-2">
                        <p><strong>ID Менеджера:</strong> {selectedLead.user.id}</p>
                        <p><strong>Ім'я Користувача:</strong> {selectedLead.user.userName}</p>
                        <p><strong>Email:</strong> {selectedLead.user.email}</p>
                        <p><strong>Група:</strong> {selectedLead.user.group ?? '—'}</p>
                        <p><strong>Тип:</strong> {selectedLead.user.type}</p>
                    </div>
                </div>
            </div>

            {/* Блок Платформа */}
            <div className="bg-white p-6 rounded-lg shadow mb-6">
                <h2 className="text-xl font-semibold mb-4">Аккаунт на платформі</h2>

                {platformLoading && <p>Завантаження даних платформи...</p>}
                {platformError && <p className="text-red-500">{platformError}</p>}

                {platformUser ? (
                    <div>
                        <p><strong>ID користувача:</strong> {platformUser.id}</p>
                        <p><strong>Email:</strong> {platformUser.email}</p>
                        <p><strong>Phone:</strong> {platformUser.phone}</p>
                        <p><strong>Country:</strong> {platformUser.country}</p>
                        <p><strong>Balance:</strong> {platformUser.balance}</p>

                        {/* Якщо щойно створили – userPassword */}
                        {userPassword && (
                            <p className="mt-2 text-green-600">
                                Пароль: <strong>{userPassword}</strong>
                            </p>
                        )}

                        {/* Відображення позицій уже в platformUser.positions */}
                        <div className="mt-4">
                            <h3 className="font-semibold mb-2">Позиції:</h3>
                            {platformUser.positions.length === 0 ? (
                                <p>Немає позицій.</p>
                            ) : (
                                <table className="min-w-full bg-white border rounded text-sm">
                                    <thead className="bg-gray-100 border-b">
                                    <tr>
                                        <th className="px-4 py-2 text-left">Pair ID</th>
                                        <th className="px-4 py-2 text-left">Type</th>
                                        <th className="px-4 py-2 text-left">Amount</th>
                                        <th className="px-4 py-2 text-left">Enter Price</th>
                                        <th className="px-4 py-2 text-left">Current Price</th>
                                        <th className="px-4 py-2 text-left">Profit</th>
                                        <th className="px-4 py-2 text-left">Is Active</th>
                                        <th className="px-4 py-2 text-left">Created</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {platformUser.positions.map((pos: Position) => (
                                        <tr key={pos.id} className="border-b">
                                            <td className="px-4 py-2">{pos.pairId}</td>
                                            <td className="px-4 py-2">{pos.type}</td>
                                            <td className="px-4 py-2">{pos.amount}</td>
                                            <td className="px-4 py-2">{pos.enterPrice}</td>
                                            <td className="px-4 py-2">{pos.currentPrice}</td>
                                            <td className="px-4 py-2">
                                                {pos.profit.toFixed(2)}
                                            </td>
                                            <td className="px-4 py-2">{pos.isActive ? 'Yes' : 'No'}</td>
                                            <td className="px-4 py-2">
                                                {new Date(pos.createdAt).toLocaleString()}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        <p>Користувач не зареєстрований на платформі.</p>
                        <button
                            onClick={handleCreatePlatformUser}
                            className="mt-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                        >
                            Створити аккаунт на платформі
                        </button>
                    </div>
                )}
            </div>

            {/* Секція коментарів */}
            <div className="mt-10">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-gray-800">Історія Коментарів</h2>
                    {(canEditStatus || canEditManager) && (
                        <button
                            onClick={() => setIsModalOpen(true)}
                            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition"
                        >
                            Додати Коментар
                        </button>
                    )}
                </div>

                <div className="bg-white p-6 rounded-lg shadow max-h-96 overflow-y-auto">
                    {commentsLoading ? (
                        <div>Завантаження коментарів...</div>
                    ) : commentsError ? (
                        <div className="text-red-500">Помилка: {commentsError}</div>
                    ) : comments.length === 0 ? (
                        <div className="text-gray-600">Немає коментарів.</div>
                    ) : (
                        <ul className="space-y-4">
                            {comments.map((comment: CommentType) => (
                                <li key={comment.id} className="flex">
                                    <div className="flex-shrink-0 mr-3">
                                        <img
                                            className="w-10 h-10 rounded-full"
                                            src={`https://ui-avatars.com/api/?name=${comment.userData.userName}`}
                                            alt={comment.userData.userName}
                                        />
                                    </div>
                                    <div>
                                        <div className="bg-gray-200 p-3 rounded-lg">
                                            <p className="text-gray-800">{comment.message}</p>
                                        </div>
                                        <span className="text-xs text-gray-500 mt-1 block">
                                            {new Date(comment.createdAt).toLocaleString()} — {comment.userData.userName}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            {/* Модалка додавання коментаря */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Додати Коментар"
            >
                <form onSubmit={handleAddComment} className="space-y-4">
                    <div>
                        <label className="block text-gray-700 mb-2">Коментар</label>
                        <textarea
                            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Введіть ваш коментар..."
                            required
                        />
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={() => setIsModalOpen(false)}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition"
                        >
                            Відмінити
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition"
                        >
                            Додати
                        </button>
                    </div>
                </form>
            </Modal>

            {/* Модалка редагування ліда */}
            {(canEditStatus || canEditManager) && (
                <Modal
                    isOpen={isEditModalOpen}
                    onClose={handleCloseEditModal}
                    title="Редагувати Лід"
                >
                    <form onSubmit={handleSaveEdit} className="space-y-4">
                        {canEditManager && (
                            <div>
                                <label className="block text-gray-700 mb-2">Менеджер</label>
                                <input
                                    type="number"
                                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                    value={editManager ?? ''}
                                    onChange={(e) => setEditManager(Number(e.target.value))}
                                    required
                                />
                                {/* Або використайте select з managers, якщо треба */}
                            </div>
                        )}
                        {canEditStatus && (
                            <div>
                                <label className="block text-gray-700 mb-2">Статус</label>
                                <select
                                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                    value={editStatus}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                    required
                                >
                                    {STATUS_OPTIONS.map((statusOption) => (
                                        <option key={statusOption} value={statusOption}>
                                            {statusOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCloseEditModal}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition"
                            >
                                Відмінити
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                            >
                                Зберегти
                            </button>
                        </div>
                    </form>
                </Modal>
            )}

            <ToastContainer />
        </div>
    );
};

export default LeadDetails;
