// src/services/platformService.ts
import axiosInstance from './axiosConfig';

export interface Position {
    id: string;
    pairId: number;
    userId: string;
    enterPrice: number;
    amount: number;
    takeProfit: string;
    stopLoss: string;
    type: 'buy' | 'sell';
    currentPrice: number;
    profit: number;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface PlatformUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    country: string;
    balance: number;
    lead_id: number;
    owner: number;
    positions: Position[]; // ← тут тепер масив позицій
}

export interface GetPlatformUserResponse {
    user: PlatformUser;
}

// для створення акаунта
export interface CreatePlatformUserResponse {
    platformUser: PlatformUser;   // повертається user + пароль
    userPassword: string;
    message: string;
}

/**
 * Створити акаунт на платформі
 * POST /platform/user/create
 * body: { leadId: '490' }
 */
export const createPlatformUser = async (token: string, leadId: number): Promise<CreatePlatformUserResponse> => {
    const response = await axiosInstance.post<CreatePlatformUserResponse>(
        '/platform/user/create',
        { leadId: leadId.toString() }, // бекенд вимагає string
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data;
};

/**
 * Отримати інформацію про користувача платформи (і відразу всі позиції)
 * GET /platform/user
 * params: { leadId: '490' }
 */
export const getPlatformUser = async (token: string, leadId: number): Promise<PlatformUser> => {
    const response = await axiosInstance.get<GetPlatformUserResponse>('/platform/user', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            leadId: leadId.toString(),
        },
    });
    return response.data.user; // user містить і positions
};
