// src/slices/platformSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    createPlatformUser,
    getPlatformUser,
    CreatePlatformUserResponse,
    PlatformUser,
} from '../services/platformService';

interface PlatformState {
    platformUser: PlatformUser | null;
    userPassword: string | null;  // коли створюємо акаунт, сюди кладемо відкритий пароль
    loading: boolean;
    error: string | null;
}

const initialState: PlatformState = {
    platformUser: null,
    userPassword: null,
    loading: false,
    error: null,
};

// 1) Створити акаунт
export const createPlatformUserThunk = createAsyncThunk<
    CreatePlatformUserResponse,
    number, // leadId
    { rejectValue: string }
>(
    'platform/createPlatformUser',
    async (leadId, thunkAPI) => {
        try {
            const token = localStorage.getItem('accessToken') || '';
            return await createPlatformUser(token, leadId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// 2) Отримати користувача (разом з позиціями)
export const getPlatformUserThunk = createAsyncThunk<
    PlatformUser,
    number, // leadId
    { rejectValue: string }
>(
    'platform/getPlatformUser',
    async (leadId, thunkAPI) => {
        try {
            const token = localStorage.getItem('accessToken') || '';
            return await getPlatformUser(token, leadId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const platformSlice = createSlice({
    name: 'platform',
    initialState,
    reducers: {
        clearPlatformData(state) {
            state.platformUser = null;
            state.userPassword = null;
            state.error = null;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        // createPlatformUserThunk
        builder
            .addCase(createPlatformUserThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createPlatformUserThunk.fulfilled, (state, action) => {
                state.loading = false;
                const { platformUser, userPassword } = action.payload;
                state.platformUser = platformUser;   // тут уже є positions, якщо повертаються
                state.userPassword = userPassword;
            })
            .addCase(createPlatformUserThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Помилка створення користувача на платформі';
            });

        // getPlatformUserThunk
        builder
            .addCase(getPlatformUserThunk.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getPlatformUserThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.platformUser = action.payload;
                state.userPassword = null; // це не створення, тому null
            })
            .addCase(getPlatformUserThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Помилка отримання користувача платформи';
            });
    },
});

export const { clearPlatformData } = platformSlice.actions;
export default platformSlice.reducer;
