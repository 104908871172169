// src/pages/Leads.tsx

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import {
    loadLeads,
    addLead,
    setCurrentPage,
    setLimit,
    updateLeadThunk,
    loadCountriesAndLanguages,
} from '../slices/leadsSlice';
import Drawer from '../components/Drawer';
import LeadFilterComponent from '../components/LeadFilterComponent';
import Pagination from '../components/Pagination';
import { CreateLeadPayload, Lead } from '../types/lead';
import { hasPermission } from '../utils/permissionUtils';
import { Role } from '../utils/permissions';
import { loadAffiliates } from '../slices/affiliateSlice';
import { loadUsers } from '../slices/userSlice';
import { LeadFilters } from '../types/leadFilters';
import Modal from '../components/Modal';
import { FaEye, FaEdit } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';

const statusStyles: Record<string, { text: string; bg: string; shadow: string }> = {
    new: { text: "text-green-900", bg: "bg-green-200", shadow: "bg-green-500" },
    "no answer": { text: "text-yellow-900", bg: "bg-yellow-200", shadow: "bg-yellow-500" },
    "newer answer": { text: "text-purple-900", bg: "bg-purple-200", shadow: "bg-purple-500" },
    "slip away": { text: "text-red-900", bg: "bg-red-200", shadow: "bg-red-500" },
    "not interested": { text: "text-gray-900", bg: "bg-gray-300", shadow: "bg-gray-500" },
    "no pot": { text: "text-orange-900", bg: "bg-orange-200", shadow: "bg-orange-500" },
    callback: { text: "text-blue-900", bg: "bg-blue-200", shadow: "bg-blue-500" },
    reassign: { text: "text-teal-900", bg: "bg-teal-200", shadow: "bg-teal-500" },
    active: { text: "text-green-900", bg: "bg-green-300", shadow: "bg-green-600" },
    "initial call": { text: "text-yellow-900", bg: "bg-yellow-300", shadow: "bg-yellow-600" },
    "invalid language": { text: "text-purple-900", bg: "bg-purple-300", shadow: "bg-purple-600" },
    "wrong info": { text: "text-red-900", bg: "bg-red-300", shadow: "bg-red-600" },
    "wrong number": { text: "text-red-800", bg: "bg-red-900", shadow: "bg-red-400" },
    depositor: { text: "text-green-900", bg: "bg-green-300", shadow: "bg-green-600" },
};

const Leads = () => {
    const dispatch = useAppDispatch();
    const { leads, loading, error, totalPages, currentPage, limit } = useAppSelector((state) => state.leads);
    const { affiliates } = useAppSelector((state) => state.affiliates);
    const { users = [] } = useAppSelector((state) => state.users);

    const role = useAppSelector((state) => state.auth.role) as Role;
    const canEditStatus = hasPermission(role, 'EDIT_STATUS');
    const canEditManager = hasPermission(role, 'EDIT_MANAGER');
    const canCreateLead = hasPermission(role, 'CREATE_LEAD');

    // <<< NEW: зберігати в state і фільтри, і локальний пошук
    const [filters, setFilters] = useState<LeadFilters>({
        page: currentPage,
        limit: limit,
        sortBy: 'lastComment',
        sortOrder: 'DESC',
    });
    const [searchTerm, setSearchTerm] = useState(''); // для локального пошуку

    useEffect(() => {
        dispatch(loadLeads(filters));
    }, [dispatch, filters]);

    useEffect(() => {
        dispatch(loadAffiliates());
        dispatch(loadUsers());
        dispatch(loadCountriesAndLanguages());
    }, [dispatch]);

    // Функція виклику фільтру
    const handleFilter = (appliedFilters: LeadFilters) => {
        setFilters({
            ...appliedFilters,
            page: 1, // щоб повернутися на першу сторінку
            limit: appliedFilters.limit || limit,
        });
        dispatch(setCurrentPage(1));
        if (appliedFilters.limit !== undefined) {
            dispatch(setLimit(appliedFilters.limit));
        }
    };

    // <<< NEW: локальний пошук (пошук по leads, які ми вже завантажили з бека)
    const handleLocalSearch = (text: string) => {
        setSearchTerm(text.toLowerCase());
    };

    // Пагінація
    const handlePageChange = (page: number) => {
        if (page < 1 || page > totalPages) return;
        setFilters((prev) => ({
            ...prev,
            page,
        }));
        dispatch(setCurrentPage(page));
    };
    const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newLimit = Number(e.target.value);
        setFilters((prev) => ({ ...prev, limit: newLimit, page: 1 }));
        dispatch(setLimit(newLimit));
        dispatch(setCurrentPage(1));
    };

    // <<< NEW: фільтрація leads по searchTerm
    const filteredLeads = leads.filter((lead) => {
        const phoneLower = lead.phone.toLowerCase();
        const emailLower = lead.email.toLowerCase();
        return (
            phoneLower.includes(searchTerm) ||
            emailLower.includes(searchTerm)
        );
    });

    // Форма створення ліда
    const [newLead, setNewLead] = useState<CreateLeadPayload>({
        userName: '',
        email: '',
        phone: '',
        country: '',
        language: '',
        affiliate: affiliates.length > 0 ? affiliates[0].id : 0,
        manager: users.length > 0 ? users[0].id : 0,
        comment: null,
        status: 'new',
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleCreateLead = () => {
        setNewLead({
            userName: '',
            email: '',
            phone: '',
            country: '',
            language: '',
            affiliate: affiliates.length > 0 ? affiliates[0].id : 0,
            manager: users.length > 0 ? users[0].id : 0,
            comment: null,
            status: 'new',
        });
        setIsDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const handleSaveLead = (e: React.FormEvent) => {
        e.preventDefault();
        const leadToSave = {
            ...newLead,
            affiliate: newLead.affiliate || 0,
            manager: newLead.manager || 0,
        };
        dispatch(addLead(leadToSave));
        handleCloseDrawer();
    };

    // Редагування лідів (один)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [leadForEdit, setLeadForEdit] = useState<{
        id: number;
        manager: number;
        status: string;
    } | null>(null);

    const handleOpenEditModal = (leadId: number, currentManager: number, currentStatus: string) => {
        setLeadForEdit({
            id: leadId,
            manager: currentManager,
            status: currentStatus,
        });
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setLeadForEdit(null);
    };

    const handleEditSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!leadForEdit) return;
        const payload: any = {
            leadIds: [leadForEdit.id],
            manager: leadForEdit.manager,
            status: leadForEdit.status,
        };
        dispatch(updateLeadThunk(payload))
            .unwrap()
            .then(() => {
                toast.success("Лід успішно оновлено!");
                dispatch(loadLeads(filters));
                handleCloseEditModal();
            })
            .catch((err) => console.error(err));
    };

    // Масове редагування (вибір лідів)
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [selectedLeads, setSelectedLeads] = useState<number[]>([]);
    const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
    const [bulkManager, setBulkManager] = useState<number | "">("");

    const toggleSelectionMode = () => {
        setIsSelectionMode(!isSelectionMode);
        setSelectedLeads([]);
    };

    const handleSelectLead = (leadId: number, checked: boolean) => {
        if (checked) {
            setSelectedLeads((prev) => [...prev, leadId]);
        } else {
            setSelectedLeads((prev) => prev.filter((id) => id !== leadId));
        }
    };

    const openBulkEditModal = () => {
        if (selectedLeads.length > 0) {
            setIsBulkEditModalOpen(true);
        }
    };

    const handleBulkEditSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedLeads.length === 0 || bulkManager === "") return;
        dispatch(updateLeadThunk({ leadIds: selectedLeads, manager: Number(bulkManager) }))
            .unwrap()
            .then(() => {
                toast.success("Менеджера для вибраних лідів успішно змінено!");
                dispatch(loadLeads(filters));
                setIsBulkEditModalOpen(false);
                setSelectedLeads([]);
                setIsSelectionMode(false);
                setBulkManager("");
            })
            .catch((err) => console.error(err));
    };

    // <<< NEW: Швидкий коментар (без відкриття LeadDetails)
    const [isQuickCommentModalOpen, setIsQuickCommentModalOpen] = useState(false);
    const [leadIdForComment, setLeadIdForComment] = useState<number | null>(null);
    const [quickComment, setQuickComment] = useState('');

    const handleOpenQuickComment = (id: number) => {
        setLeadIdForComment(id);
        setQuickComment('');
        setIsQuickCommentModalOpen(true);
    };
    const handleCloseQuickCommentModal = () => {
        setIsQuickCommentModalOpen(false);
        setLeadIdForComment(null);
        setQuickComment('');
    };
    const handleSubmitQuickComment = (e: React.FormEvent) => {
        e.preventDefault();
        if (!leadIdForComment) return;
        // addComment - із commentSlice
        import('../slices/commentSlice')
            .then(({ addComment }) => {
                dispatch(addComment({ message: quickComment, leadId: leadIdForComment }))
                    .unwrap()
                    .then(() => {
                        toast.success('Коментар додано');
                        // Перезавантажити ліди, щоби оновився lastComment:
                        dispatch(loadLeads(filters));
                    })
                    .catch((err) => toast.error('Помилка додавання коментаря'))
                    .finally(() => {
                        handleCloseQuickCommentModal();
                    });
            })
            .catch((err) => {
                console.error('commentSlice not found', err);
            });
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
                <h1 className="text-3xl font-bold text-gray-800">Ліди</h1>
                <div className="flex items-center space-x-4 mt-4 lg:mt-0">
                    {canEditManager && (
                        <button
                            onClick={toggleSelectionMode}
                            className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-200"
                        >
                            {isSelectionMode ? "Вихід з вибору" : "Вибрати"}
                        </button>
                    )}
                    {canCreateLead && (
                        <button
                            onClick={handleCreateLead}
                            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200 flex items-center"
                        >
                            Створити ліда
                        </button>
                    )}
                </div>
            </div>

            {/* Компонент фільтру */}
            <LeadFilterComponent onFilter={handleFilter} onSearchLocal={handleLocalSearch} />

            <div className="flex items-center mb-4">
                <label className="mr-2 text-gray-700">Лідів на сторінку:</label>
                <select
                    value={filters.limit}
                    onChange={handleLimitChange}
                    className="px-3 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    {[10, 50, 100, 150, 200, 250].map((val) => (
                        <option key={val} value={val}>{val}</option>
                    ))}
                </select>
            </div>

            {isSelectionMode && selectedLeads.length > 0 && canEditManager && (
                <div className="mb-4">
                    <button
                        onClick={openBulkEditModal}
                        className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                    >
                        Змінити менеджера для вибраних
                    </button>
                </div>
            )}

            <div className="w-full overflow-x-auto">
                <table className="min-w-[1400px] table-fixed border-collapse leading-normal">
                    <thead>
                    <tr>
                        {isSelectionMode && (
                            <th className="px-5 py-3 border-b border-gray-200 text-gray-800 text-left text-sm font-semibold whitespace-nowrap">
                                Вибір
                            </th>
                        )}
                        {[
                            'ID',
                            "Ім'я та Email",
                            'Телефон',
                            'Країна',
                            'Мова',
                            'Афіліат',
                            'Менеджер',
                            'Статус',
                            'Останній коментар',
                            'Дата створення',
                            'Дії',
                        ].map((header) => (
                            <th
                                key={header}
                                className="px-5 py-3 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold whitespace-nowrap"
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={10} className="px-5 py-5 text-center">
                                Завантаження...
                            </td>
                        </tr>
                    ) : error ? (
                        <tr>
                            <td colSpan={10} className="px-5 py-5 text-center text-red-500">
                                Помилка: {error}
                            </td>
                        </tr>
                    ) : filteredLeads.length === 0 ? (
                        <tr>
                            <td colSpan={10} className="px-5 py-5 text-center text-gray-600">
                                Немає лідів за обраними фільтрами або пошуком.
                            </td>
                        </tr>
                    ) : (
                        filteredLeads.map((lead: Lead, index) => (
                            <tr
                                key={lead.id}
                                className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100 transition duration-200`}
                            >
                                {isSelectionMode && (
                                    <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">
                                        <input
                                            type="checkbox"
                                            checked={selectedLeads.includes(lead.id)}
                                            onChange={(e) => handleSelectLead(lead.id, e.target.checked)}
                                        />
                                    </td>
                                )}
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">{lead.id}</td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">
                                    <div>
                                        {lead.userName}
                                    </div>
                                    <div className="text-xs text-gray-500 mt-1">
                                        {lead.email}
                                    </div>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">{lead.phone}</td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">{lead.country}</td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">{lead.language}</td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">
                                    <div className="flex items-center whitespace-nowrap">
                                        <div className="flex-shrink-0 w-10 h-10">
                                            <img
                                                className="w-full h-full rounded-full"
                                                src={lead.affiliateData.url ? lead.affiliateData.url : 'https://via.placeholder.com/40'}
                                                alt="Affiliate"
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-gray-900">{lead.affiliateData.offerName}</p>
                                            <p className="text-gray-600 text-sm">{lead.affiliateData.offer}</p>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">
                                    {lead.user.userName}
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">
                                        <span
                                            className={`relative inline-block px-3 py-1 font-semibold leading-tight ${
                                                statusStyles[lead.status]?.text || "text-gray-900"
                                            } ${statusStyles[lead.status]?.bg || "bg-gray-200"} rounded-full`}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`absolute inset-0 opacity-50 rounded-full ${
                                                    statusStyles[lead.status]?.shadow || "bg-gray-500"
                                                }`}
                                            />
                                            <span className="relative capitalize">{lead.status}</span>
                                        </span>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-normal">
                                    <div>
                                        {lead.lastComment ? lead.lastComment.message : '—'}
                                    </div>
                                    <div className="text-xs text-gray-500 mt-1">
                                        {lead.lastComment
                                            ? format(new Date(lead.lastComment.createdAt), 'dd.MM.yyyy HH:mm')
                                            : '—'}
                                    </div>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap">
                                    {lead.createdAt ? format(new Date(lead.createdAt), 'dd.MM.yyyy HH:mm') : '—'}
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 text-sm whitespace-nowrap flex space-x-4 items-center">
                                    {/* Посилання на деталі */}
                                    <a
                                        href={`/leads/${lead.id}`}
                                        className="text-blue-600 hover:text-blue-800 flex items-center"
                                        title="Переглянути лід"
                                    >
                                        <FaEye/>
                                    </a>
                                    {/* Редагувати */}
                                    {(canEditStatus || canEditManager) && !isSelectionMode && (
                                        <button
                                            onClick={() => handleOpenEditModal(lead.id, lead.user.id, lead.status)}
                                            className="text-yellow-500 hover:text-yellow-700 flex items-center"
                                            title="Редагувати лід"
                                        >
                                            <FaEdit/>
                                        </button>
                                    )}
                                    {/* <<< NEW: Швидкий коментар */}
                                    <button
                                        onClick={() => handleOpenQuickComment(lead.id)}
                                        className="text-green-600 hover:text-green-800"
                                        title="Залишити коментар"
                                    >
                                        💬
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>

            {!loading && filteredLeads.length > 0 && totalPages > 1 && (
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}

            {/* Drawer для створення ліда */}
            {canCreateLead && (
                <Drawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} title="Створити нового ліда">
                    <form onSubmit={handleSaveLead} className="space-y-4">
                        <div>
                            <label className="block text-gray-700 mb-2">Ім'я</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.userName}
                                onChange={(e) => setNewLead({ ...newLead, userName: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Email</label>
                            <input
                                type="email"
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.email}
                                onChange={(e) => setNewLead({ ...newLead, email: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Телефон</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.phone}
                                onChange={(e) => setNewLead({ ...newLead, phone: e.target.value })}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Країна</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.country}
                                onChange={(e) => setNewLead({ ...newLead, country: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Мова</label>
                            <input
                                type="text"
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.language}
                                onChange={(e) => setNewLead({ ...newLead, language: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Афіліат</label>
                            <select
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.affiliate}
                                onChange={(e) => setNewLead({ ...newLead, affiliate: Number(e.target.value) })}
                                required
                            >
                                <option value="">Оберіть афіліата</option>
                                {affiliates.map((aff) => (
                                    <option key={aff.id} value={aff.id}>
                                        {aff.offerName} - {aff.offer}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Менеджер</label>
                            <select
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.manager}
                                onChange={(e) => setNewLead({ ...newLead, manager: Number(e.target.value) })}
                                required
                            >
                                <option value="">Оберіть менеджера</option>
                                {users.map((manager) => (
                                    <option key={manager.id} value={manager.id}>
                                        {manager.userName} ({manager.email})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Коментар</label>
                            <textarea
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.comment || ''}
                                onChange={(e) => setNewLead({ ...newLead, comment: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 mb-2">Статус</label>
                            <select
                                className="w-full px-4 py-2 border rounded-lg"
                                value={newLead.status}
                                onChange={(e) => setNewLead({ ...newLead, status: e.target.value })}
                                required
                            >
                                {Object.keys(statusStyles).map((statusOption) => (
                                    <option key={statusOption} value={statusOption}>
                                        {statusOption}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCloseDrawer}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
                            >
                                Відмінити
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
                            >
                                Зберегти
                            </button>
                        </div>
                    </form>
                </Drawer>
            )}

            {isEditModalOpen && leadForEdit && (
                <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal} title="Редагувати Лід">
                    <form onSubmit={handleEditSubmit} className="space-y-4">
                        {canEditManager && (
                            <div>
                                <label className="block text-gray-700 mb-2">Менеджер</label>
                                <select
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                                    value={leadForEdit.manager}
                                    onChange={(e) =>
                                        setLeadForEdit({ ...leadForEdit!, manager: Number(e.target.value) })
                                    }
                                    required
                                >
                                    <option value="">Оберіть менеджера</option>
                                    {users.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.userName} ({user.email})
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {canEditStatus && (
                            <div>
                                <label className="block text-gray-700 mb-2">Статус</label>
                                <select
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                                    value={leadForEdit.status}
                                    onChange={(e) => setLeadForEdit({ ...leadForEdit!, status: e.target.value })}
                                    required
                                >
                                    {Object.keys(statusStyles).map((statusOption) => (
                                        <option key={statusOption} value={statusOption}>
                                            {statusOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCloseEditModal}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
                            >
                                Відмінити
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-200"
                            >
                                Зберегти
                            </button>
                        </div>
                    </form>
                </Modal>
            )}

            {isBulkEditModalOpen && (
                <Modal
                    isOpen={isBulkEditModalOpen}
                    onClose={() => setIsBulkEditModalOpen(false)}
                    title="Змінити менеджера для вибраних лідів"
                >
                    <form onSubmit={handleBulkEditSubmit} className="space-y-4">
                        <div>
                            <label className="block text-gray-700 mb-2">Менеджер</label>
                            <select
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                                value={bulkManager}
                                onChange={(e) => setBulkManager(+e.target.value)}
                                required
                            >
                                <option value="">Оберіть менеджера</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.userName} ({user.email})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={() => setIsBulkEditModalOpen(false)}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
                            >
                                Відмінити
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-200"
                            >
                                Зберегти
                            </button>
                        </div>
                    </form>
                </Modal>
            )}

            {/* <<< NEW: Модалка Швидкого коментаря */}
            {isQuickCommentModalOpen && (
                <Modal
                    isOpen={isQuickCommentModalOpen}
                    onClose={handleCloseQuickCommentModal}
                    title="Додати коментар"
                >
                    <form onSubmit={handleSubmitQuickComment} className="space-y-4">
                        <div>
                            <label className="block text-gray-700 mb-2">Коментар</label>
                            <textarea
                                className="w-full px-4 py-2 border rounded-lg"
                                value={quickComment}
                                onChange={(e) => setQuickComment(e.target.value)}
                                required
                            />
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCloseQuickCommentModal}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200"
                            >
                                Відмінити
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                            >
                                Додати
                            </button>
                        </div>
                    </form>
                </Modal>
            )}

            <ToastContainer />
        </div>
    );
};

export default Leads;
