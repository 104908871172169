// src/components/LeadFilterComponent.tsx

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LeadFilters } from '../types/leadFilters';
import { useAppSelector } from '../hooks/hooks';
import { Role } from '../utils/permissions';

interface LeadFilterComponentProps {
    onFilter: (filters: LeadFilters) => void;
    onSearchLocal: (searchText: string) => void;
}

const statusOptions = [
    'new',
    'no answer',
    'newer answer',
    'slip away',
    'not interested',
    'no pot',
    'callback',
    'reassign',
    'active',
    'depositor',
    'initial call',
    'wrong info',
    'invalid language',
    'wrong number',
];

const sortFieldOptions = [
    { value: 'createdAt', label: 'Дата створення' },
    { value: 'lastComment', label: 'Дата останнього коментаря' },
];

const sortOrderOptions = [
    { value: 'ASC', label: 'За зростанням' },
    { value: 'DESC', label: 'За спаданням' },
];

const LeadFilterComponent: React.FC<LeadFilterComponentProps> = ({ onFilter, onSearchLocal }) => {
    // фільтри
    const [status, setStatus] = useState<string>('');
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);

    const role = useAppSelector((state) => state.auth.role) as Role;
    const users = useAppSelector((state) => state.users.users);
    const countries = useAppSelector((state) => state.leads.countries);
    const languages = useAppSelector((state) => state.leads.languages);

    const [managerId, setManagerId] = useState<number | undefined>(undefined);
    const [country, setCountry] = useState<string>('');
    const [language, setLanguage] = useState<string>('');

    // пошук
    const [searchText, setSearchText] = useState('');

    // <<< NEW: поля сортування
    const [sortBy, setSortBy] = useState<string>('lastComment'); // дефолт
    const [sortOrder, setSortOrder] = useState<string>('DESC');   // дефолт

    const handleApplyFilters = () => {
        const appliedFilters: LeadFilters = {};

        if (status) {
            appliedFilters.status = status;
        }
        if (startDate && endDate) {
            appliedFilters.dateRange = [
                startDate.toISOString(),
                endDate.toISOString(),
            ];
        }
        if (['head', 'shift', 'teamLead'].includes(role) && managerId) {
            appliedFilters.managerId = managerId;
        }
        if (country) {
            appliedFilters.country = country;
        }
        if (language) {
            appliedFilters.language = language;
        }

        // <<< Додаємо вибрані поля сортування
        appliedFilters.sortBy = sortBy;
        appliedFilters.sortOrder = sortOrder;

        onFilter(appliedFilters);
    };

    const handleResetFilters = () => {
        setStatus('');
        setStartDate(undefined);
        setEndDate(undefined);
        setManagerId(undefined);
        setCountry('');
        setLanguage('');
        setSearchText('');

        // Скидаємо сортування на дефолт, якщо бажано:
        setSortBy('lastComment');
        setSortOrder('DESC');

        onFilter({});        // передаємо в батьківський компонет порожній об’єкт
        onSearchLocal('');   // і скидаємо локальний пошук
    };

    return (
        <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4 mb-6">
            {/* Статус */}
            <div>
                <label className="block text-gray-700 mb-2">Статус</label>
                <select
                    className="w-full px-3 py-2 border rounded"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <option value="">Всі статуси</option>
                    {statusOptions.map((statusOption) => (
                        <option key={statusOption} value={statusOption}>
                            {statusOption}
                        </option>
                    ))}
                </select>
            </div>

            {/* Дата */}
            <div>
                <label className="block text-gray-700 mb-2">Дата</label>
                <div className="flex space-x-2">
                    <DatePicker
                        selected={startDate}
                        onChange={(date: Date | null) => setStartDate(date || undefined)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Початкова дата"
                        className="w-full px-3 py-2 border rounded"
                        isClearable
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date: Date | null) => setEndDate(date || undefined)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="Кінцева дата"
                        className="w-full px-3 py-2 border rounded"
                        isClearable
                    />
                </div>
            </div>

            {/* Менеджер */}
            {['head', 'shift', 'teamLead'].includes(role) && (
                <div>
                    <label className="block text-gray-700 mb-2">Менеджер</label>
                    <select
                        className="w-full px-3 py-2 border rounded"
                        value={managerId || ''}
                        onChange={(e) => setManagerId(e.target.value ? Number(e.target.value) : undefined)}
                    >
                        <option value="">Всі менеджери</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.userName} ({user.email})
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {/* Країна */}
            <div>
                <label className="block text-gray-700 mb-2">Країна</label>
                <select
                    className="w-full px-3 py-2 border rounded"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                >
                    <option value="">Всі країни</option>
                    {countries.map((c) => (
                        <option key={c} value={c}>
                            {c}
                        </option>
                    ))}
                </select>
            </div>

            {/* Мова */}
            <div>
                <label className="block text-gray-700 mb-2">Мова</label>
                <select
                    className="w-full px-3 py-2 border rounded"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                >
                    <option value="">Всі мови</option>
                    {languages.map((l) => (
                        <option key={l} value={l}>
                            {l}
                        </option>
                    ))}
                </select>
            </div>

            {/* Пошук */}
            <div>
                <label className="block text-gray-700 mb-2">Пошук (email/phone)</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border rounded"
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                        onSearchLocal(e.target.value);
                    }}
                    placeholder="Введіть email або телефон..."
                />
            </div>

            {/* <<< NEW: Блок сортування */}
            <div>
                <label className="block text-gray-700 mb-2">Сортувати за</label>
                <div className="flex space-x-2">
                    {/* Вибір поля сорту */}
                    <select
                        className="px-3 py-2 border rounded"
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                    >
                        {sortFieldOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>

                    {/* Вибір напрямку */}
                    <select
                        className="px-3 py-2 border rounded"
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                    >
                        {sortOrderOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Кнопки */}
            <div className="flex space-x-2 mt-4 md:mt-0">
                <button
                    onClick={handleApplyFilters}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                    Фільтрувати
                </button>
                <button
                    onClick={handleResetFilters}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                    Скинути
                </button>
            </div>
        </div>
    );
};

export default LeadFilterComponent;
